<template>
   <div>
      <v-carousel hide-delimiters height="calc(100vh - 64px)">
         <v-carousel-item v-for="(item, i) in slides" :key="i" :src="item.src">
            <div class="d-flex justify-center align-end pa-4" style="height: 100%">
               <v-card class="pa-4 card-effect" max-width="500" elevation="5">
                  <v-card-title>
                     {{ item.title }}
                  </v-card-title>
                  <v-card-text>
                     {{ item.subtitle }}
                  </v-card-text>
                  <v-card-actions>
                     <v-btn v-for="(btn, e) in item.buttons" :key="e" color="primary" @click="$router.push({ name: btn.action })">{{
                        btn.text
                     }}</v-btn>
                  </v-card-actions>
               </v-card>
            </div>
         </v-carousel-item>
      </v-carousel>
   </div>
</template>

<script>
export default {
   data: () => {
      return {
         slides: [
            {
               src: require("../assets/background.jpg"),
               title: "Herramientas emprersariales",
               subtitle: "En un solo lugar",
            },
            {
               src: require("../assets/background2.jpg"),
               title: "Conoce nuestras plataformas",
               subtitle: "Administracion empresarial",
            },
            {
               src: require("../assets/background3.jpg"),
               title: "Aplicaciones ligadas",
               subtitle: "A Microsip",
            },
         ],
      };
   },
};
</script>

<style></style>
