<template>
   <div>
      <v-app-bar>
         <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
         <v-spacer></v-spacer>
         <v-img :src="logo" height="55px" width="120px" contain style="margin-right: 24px"></v-img>
         <v-spacer></v-spacer>
      </v-app-bar>
      <v-navigation-drawer v-model="drawer" absolute bottom temporary>
         <v-list nav dense>
            <v-list-item-group v-model="group">
               <v-list-item v-for="(item, index) in apps" :key="index" :href="item.link" target="_blank" rel="noopener noreferrer">
                  <v-list-item-icon>
                     <v-icon>{{item.logo}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{item.title}}</v-list-item-title>
               </v-list-item>
            </v-list-item-group>
         </v-list>
      </v-navigation-drawer>
   </div>
</template>

<script>
export default {
   data: () => ({
      drawer: false,
      group: null,
      logo: require("../assets/adconline.png"),
      apps: [
         {
            link: "http://qs.adconline.com.mx",
            title: "Quick Support",
            logo: "mdi-handshake",
         },
         {
            link: "https://cobranzamsj.com/",
            title: "Pegaso Mensajería",
            logo: "mdi-horse-variant-fast",
         },
         {
            link: "http://asesoriasdecuauhtemoc.com/",
            title: "Asesorías de Cuauhtémoc",
            logo: "mdi-tie",
         },
      ],
   }),

   watch: {
      group() {
         this.drawer = false;
      },
   },
};
</script>
