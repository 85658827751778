<template>
  <v-app>
    <Nav/>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Nav from "./components/Nav.vue"

export default {
  components:{
    Nav
  },
  data: () => ({
  }),
};
</script>
